body {
  background: #f4f4f9;
}
.gridLogins {
  padding: 1em;
  text-align: center;
}
.gridContainer {
  border-radius: 2em;
  padding: 0.5em;
  margin: 0 auto;
  margin-top: 2em;
  width: 45%;
}

.gridCont {
  border-radius: 2em;
}

.textField {
  display: block;
  width: 70%;
}
.textField2 {
  margin: 0.5em !important;
}
.btn {
  text-align: center;
  margin: 0 auto !important;
  margin-top: 2em !important;
  display: block !important;
  width: 70%;
}

.loginOptions {
  display: flex;
  padding: 0.5em;
  margin-top: 1em;
  align-items: center;
  justify-content: space-around;
}
.titleLogins {
  font-size: 35px !important;
}

#subtitle {
  margin-top: 0.5em;
  font-weight: 400;
}

.billinStyle {
  background: #fff;
  margin-bottom: 2em !important;
  border-radius: 1em;
}
.address {
  font-size: 16px;
  padding: 1em;
}

.titleGrids {
  padding: 1em 0.5em;
  background: #6017cf;
  color: #fff;
  text-align: center;
  font-size: 1.1rem;
  border-right: solid #fff 2px;
  border-bottom: 2px solid #ffc107;
}

#gridTy {
  border-right: none;
}

.gridCont {
  padding: 0.5em;
}

#leftContent {
  margin-left: 2em;
  width: 90%;
}

#mainPlanTitle {
  font-size: 1.5rem;
}

/*Drawer*/
.gridDrawer {
  background: #f4f4f9;
  min-height: 100vh;
}

.sidesDrawes {
  background: #fefefe;
  padding: 2em 0 0 0;
  text-align: center;
}
#sidedRA {
  left: 0;
  height: 100%;
  top: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
}

.middleDrawes {
  margin-left: 17vw !important;
}
#sidedRA2 {
  right: 0;
  height: 100%;
  top: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
}
#logo {
  margin: 0 auto;
  width: 70%;
}

.menuDrawer {
  color: #2f6fd9;
  padding: 1em 0;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  transition: all 0.5s ease;
}

#menuDra {
  width: 100%;
}
.menuDrawer:hover {
  background: #7894f0;
  color: #fff;
}
#iconDr,
#textDr {
  padding: 0 0.5em;
}
#iconDr {
  margin-left: 1.8em;
}

#avatarImg {
  background: #2f6fd9;
  height: 150px;
  margin-left: 2.5em;
  width: 150px;
}
#drawerAvatarText {
  font-size: 20px;
  font-weight: 500;
}

.containerWB {
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 10px;
  background: #5a7ff8;
  border-radius: 2em;
  color: #fefefe;
  display: flex;
  margin: 0 auto;
  margin-top: 1em;
  padding: 1em 3em;
  width: 80%;
  justify-content: space-between;
}

#imgwp {
  width: 30%;
}
.containerMiDr {
  display: flex;
  padding: 2em 3em;
  justify-content: space-between;
}
.ConDashCl {
  display: flex;
  margin: 0 auto;
  margin-top: 2em;
  justify-content: space-between;
  width: 90%;
}
#plan {
  width: 32%;
}
.title4 {
  font-size: 18px !important;
}
.title5 {
  font-size: 16px !important;
}
.titleicons4 {
  font-size: 12px !important;
}
#dashTable,
.midSub,
.midSub2 {
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 10px;
  border-radius: 2em;
  background: #fefefe;
  overflow: hidden;
  width: 65%;
}

#topTa,
#contentTab {
  padding: 1em;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

#topTa {
  background: #5a7ff8;
  color: #fefefe;
}

#topTa div {
  width: 35%;
  border-right: 1px solid #ffffff;
  text-align: center;
}

#contentTab {
  border-bottom: 1px solid #aaa;
}
#contentTab div {
  width: 35%;
  text-align: center;
  border-right: 1px solid #aaa;
}
.midSub,
.midSub2 {
  text-align: center;
  margin: 0 auto;
  margin-top: 2em;
  width: 90%;
}
.midSub2 {
  padding: 1em;
}
#contentTab2 div {
  text-align: center;
  margin: 1em;
}
/*Generate bill*/
#generateBill {
  background: #f4f4f9;
  margin: 0 auto;
  margin-top: 2em;
  padding-top: 2em;
  width: 90%;
}
#topBill {
  display: flex;
  justify-content: space-around;
}
#logobill {
  width: 30%;
}
#neg {
  font-weight: 600;
}
#midBillInfo {
  margin-top: 2em;
}
#h3bill {
  border-bottom: 2px solid #7894f0;
  font-size: 30px;
  padding-bottom: 0.5em;
  margin-top: 1em;
  font-weight: 500;
}
#tableBill {
  border-collapse: collapse;
  text-align: center;
  width: 100%;
}
td,
th {
  padding: 1em;
  border: 1px solid #8fa5ec;
}

#footerBill {
  margin-top: 3em;
}

#contactBill {
  text-align: center;
}

/*generate Sub*/
#containerSubsGene {
  margin: 0 auto;
  padding: 2em;
}

.dashTablesub {
  background: #fefefe;
  border-radius: 2em 2em 0 0;
  overflow: hidden;
  width: 90%;
  margin: 0 auto;
}
#contentSubGen {
  padding: 2em;
}

.contentTabGenerSub {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.contentTabGenerSub div {
  border: 1px solid #aaa;
  width: 100%;
  padding: 1em;
  text-align: left !important;
}
#titleSubGene,
#titleSubGene2 {
  font-size: 20px;
  padding: 0;
  margin: 0;
}

#titleSubGene2 {
  margin-left: 2.5em;
  margin-bottom: 1.5em;
}

#midContentSub {
  margin-top: 1em;
}
.toptabSub {
  font-weight: 500;
}
#billinAddr {
  margin-top: 2em;
  margin-bottom: 2em;
}
#billinAddr div {
  width: 80%;
}
